"use client";

import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import BaseRoomCarousel from "@/common/partials/RoomCarousel/BaseRoomCarousel";
import { FavoriteRoom } from "~/db/models/FavoriteRoom";
import { GetSearchRoomsByIdQuery, useGetSearchRoomsByIdQuery } from "~/generated/graphql";
import {
  AvailableStartDatePresentable,
  AvailableStartDatePresenter,
} from "~/types/Mixins/AvailableStartDatePresenter";
import { SearchBuilding } from "~/types/SearchBuildingType";
import { SearchRoom } from "~/types/SearchRoomType";
import { nonNullable } from "~/utils/typeUtil";

const SearchRoomPresenter = AvailableStartDatePresentable(SearchRoom);
const DISPLAY_COUNT = 4;

type Props = {
  title: JSX.Element;
  excludeRoomId?: number;
};

/**
 * お気に入り物件のカルーセルコンポーネント
 */
const FavoriteRoomCarousel = (props: Props): JSX.Element => {
  const router = useRouter();
  const [favoriteRooms, setFavoriteRooms] = useState<FavoriteRoom[]>([]);

  const getFavoriteRooms = async () => {
    const favoriteRooms = await FavoriteRoom.getAllExcept(
      [props.excludeRoomId].filter(nonNullable),
    );
    setFavoriteRooms(favoriteRooms);
  };

  const extractRoomIds = (favoriteRooms: FavoriteRoom[]) => {
    return favoriteRooms.map((r) => r.roomId);
  };

  const buildRooms = (roomsData: GetSearchRoomsByIdQuery | undefined) => {
    if (!roomsData) return [];
    if (!roomsData.search_rooms?.data) return [];

    return roomsData.search_rooms.data
      .map((v) => {
        const room: SearchRoom & AvailableStartDatePresenter = new SearchRoomPresenter(
          v,
          new SearchBuilding({ ...v.building, rooms: [] }),
        );
        return room;
      })
      .filter((v) => v.isViewingAvailable())
      .sort((a, b) => {
        const aViewedAtTime = a.viewed_at?.getTime() || 0;
        const bViewedAtTime = b.viewed_at?.getTime() || 0;
        return bViewedAtTime - aViewedAtTime;
      })
      .slice(0, DISPLAY_COUNT);
  };

  const { loading, error, data } = useGetSearchRoomsByIdQuery({
    variables: {
      roomIds: extractRoomIds(favoriteRooms),
    },
  });
  const rooms = buildRooms(data);

  useEffect(() => {
    getFavoriteRooms();
  }, [router]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || error || rooms.length === 0) {
    return <></>;
  }

  return <BaseRoomCarousel rooms={rooms} title={props.title} />;
};

export default FavoriteRoomCarousel;
