import(/* webpackMode: "eager" */ "/app/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/ApolloNextAppProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/hooks.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/src/components/common/buttons/FavoriteButton/FavoritePictButton.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/icons/InitialCostScore/InitialCostScore.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/Image/ImageWithNoImage.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/Loading/Loading.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/PassionContent/Passion.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/QAContent/QA.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/PopularLinks/PopularLinks.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/Panel/NewArrivedBuildingList.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/icons/Stars/Stars.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/QAContent/QAItem.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/RoomCarousel/RoomCarousel.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/RoomCard/RoomCard.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/SurveyContent/SurveyContent.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/SurveyContent/SurveyPopover.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/SurveyContent/SurveySideTab.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/common/partials/UpdatePoint/UpdatePoint.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/decorator/FavoriteToggle.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/list/svgs/purpleStars.svg");
import(/* webpackMode: "eager" */ "/app/src/components/pages/top/Categories/Categories.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/pages/top/Hero/Hero.tsx");
import(/* webpackMode: "eager" */ "/app/src/styles/pages/top/Top.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/pages/top/PublicationAgents/PublicationAgents.module.scss")