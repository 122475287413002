import Conditions from "@/pages/list/Logic/Condition/Conditions";
import { DictionaryQuery } from "@/pages/list/Logic/Condition/QueryType";
import {
  ConditionColumn,
  SqlOperator,
  QueryListPageQueryWhereWhereConditions,
} from "~/generated/graphql";
import { categoryConditions, CategoryConditionEntity } from "~/types/CategoryConditionType";
import RegionConditionType from "~/types/regionCondition/RegionConditionType";
import RouteConditionType from "~/types/routeCondition/RouteConditionType";
import { valueOf } from "~/utils/typeUtil";

export class WheresConverter {
  static listPageWhere(
    conditions: Conditions,
    options?: {
      excludeBuildingIds?: string[];
      excludeRoomIds?: string[];
    },
  ): QueryListPageQueryWhereWhereConditions | undefined {
    const buildingWhere = buildingRelatedWhere(conditions, options?.excludeBuildingIds);
    const roomWhere = roomRelatedWhere(conditions, options?.excludeRoomIds);
    const wordingWhere = wordingRelatedWhere(conditions.keywordCondition);

    if (!buildingWhere && !roomWhere && !wordingWhere) return;

    return {
      AND: [
        buildingWhere && {
          HAS: {
            relation: "building",
            condition: buildingWhere,
          },
        },
        roomWhere,
        wordingWhere,
      ].filter((v): v is QueryListPageQueryWhereWhereConditions => !!v),
    };
  }

  static listPageRoomWhere(
    conditions: Conditions,
    buildingIds: number[],
    excludeRoomIds?: string[],
  ): QueryListPageQueryWhereWhereConditions | undefined {
    const roomWhere = roomRelatedWhere(conditions, excludeRoomIds);
    const buildingIdWhere = {
      column: ConditionColumn.BuildingId,
      operator: SqlOperator.In,
      value: buildingIds,
    };

    if (!roomWhere) return buildingIdWhere;

    return {
      AND: [roomWhere, buildingIdWhere],
    };
  }
}

const buildingRelatedWhere = (
  conditions: Conditions,
  excludeBuildingIds?: string[],
): QueryListPageQueryWhereWhereConditions => {
  const andConditions = {
    buildingAge: buildingAgeWhere(
      conditions.lowerBuildingAgeCondition,
      conditions.upperBuildingAgeCondition,
    ),
    construction: constructionWhere(
      conditions.constructionCondition as {
        [K in CONSTRUCTION_CONDITION]: boolean;
      },
    ),
    area: areaWhere(conditions.regionCondition),
    station: stationWhere(conditions.routeCondition, conditions.upperFootCondition),
    categoryWhere: categoryWhere("building", conditions.categoriesCondition),
    excludeBuildingIds: excludeIdsWhere(excludeBuildingIds || []),
  };

  const andWheres = Object.values(andConditions).filter(Boolean);

  if (andWheres.length === 0) return {};
  if (andWheres.length === 1) return andWheres[0];

  return {
    AND: andWheres,
  };
};

const roomRelatedWhere = (
  conditions: Conditions,
  excludeRoomIds?: string[],
): QueryListPageQueryWhereWhereConditions => {
  const andConditions = {
    square: squareWhere(conditions.lowerAreaCondition, conditions.upperAreaCondition),
    floorPlan: floorPlanWhere(
      Object.keys(conditions.floorPlanCondition).map((floorPlanText) => {
        return {
          roomNum: Number(floorPlanText.split("_")[0]),
          planName: floorPlanText.split("_")[1] as FLOOR_PLAN_CONDITION["planName"],
        };
      }),
    ),
    initialCostScore: initialCostScoreWhere(conditions.lowerInitialCostScoreCondition),
    rent: rentWhere(
      conditions.lowerRentCondition,
      conditions.upperRentCondition,
      conditions.includeAdministrationFeeCondition,
    ),
    category: categoryWhere("room", conditions.categoriesCondition),
    estateAgentName: propertyAgentNameWhere(conditions.propertyAgentNameCondition),
    excludeRoomIds: excludeIdsWhere(excludeRoomIds || []),
  };
  const andWheres = Object.values(andConditions).filter(Boolean);

  if (andWheres.length === 0) return {};
  if (andWheres.length === 1) return andWheres[0];

  return {
    AND: andWheres,
  };
};

const wordingRelatedWhere = (keyword?: string): QueryListPageQueryWhereWhereConditions => {
  if (!keyword) return {};

  const buildingWordingWhere = {
    OR: [
      ConditionColumn.BuildingName,
      ConditionColumn.BuildingNameFurigana,
      ConditionColumn.StationName_1,
      ConditionColumn.StationName_2,
      ConditionColumn.StationName_3,
      ConditionColumn.PrefectureName,
      ConditionColumn.CityName,
      ConditionColumn.TownName,
      ConditionColumn.EstateAgentName,
      ConditionColumn.Memo,
    ].map((column) => {
      return {
        column: column,
        operator: SqlOperator.Like,
        value: `%${keyword}%`,
      };
    }),
  };

  const roomWordingWhere = {
    OR: [ConditionColumn.Id].map((column) => {
      return {
        column: column,
        operator: SqlOperator.Eq,
        value: `${keyword}`,
      };
    }),
  };

  return {
    OR: [
      {
        HAS: {
          relation: "building",
          condition: buildingWordingWhere,
        },
      },
      roomWordingWhere,
    ],
  };
};

const squareWhere = (
  lowerSquareMeters?: string,
  upperSquareMeters?: string,
): QueryListPageQueryWhereWhereConditions => {
  if (!lowerSquareMeters && !upperSquareMeters) return {};

  const lowerCondition = {
    column: ConditionColumn.SquareMeters,
    operator: SqlOperator.Gte,
    value: lowerSquareMeters,
  };
  const upperCondition = {
    column: ConditionColumn.SquareMeters,
    operator: SqlOperator.Lte,
    value: upperSquareMeters,
  };

  if (lowerSquareMeters && !upperSquareMeters) return lowerCondition;
  if (!lowerSquareMeters && upperSquareMeters) return upperCondition;

  return {
    AND: [lowerCondition, upperCondition],
  };
};

const buildingAgeWhere = (
  lowerAge?: string,
  upperAge?: string,
): QueryListPageQueryWhereWhereConditions => {
  if (!lowerAge && !upperAge) return {};

  const lowerCondition = {
    column: ConditionColumn.BuildingAge,
    operator: SqlOperator.Gte,
    value: lowerAge,
  };
  const upperCondition = {
    column: ConditionColumn.BuildingAge,
    operator: SqlOperator.Lte,
    value: upperAge,
  };

  if (lowerAge && !upperAge) return lowerCondition;
  if (!lowerAge && upperAge) return upperCondition;

  return {
    AND: [lowerCondition, upperCondition],
  };
};

type CONSTRUCTION_CONDITION = "1" | "2" | "3" | "4";
const constructionWhere = (constructions: {
  [K in CONSTRUCTION_CONDITION]: boolean;
}): QueryListPageQueryWhereWhereConditions => {
  const wheres: QueryListPageQueryWhereWhereConditions[] = [];

  if (constructions[1]) {
    wheres.push({
      column: ConditionColumn.ConstructionId,
      operator: SqlOperator.In,
      value: [4, 5],
    });
  }
  if (constructions[2]) {
    wheres.push({
      column: ConditionColumn.ConstructionId,
      operator: SqlOperator.In,
      value: [3, 7, 9],
    });
  }
  if (constructions[3]) {
    wheres.push({
      column: ConditionColumn.ConstructionId,
      operator: SqlOperator.Eq,
      value: 1,
    });
  }
  if (constructions[4]) {
    wheres.push({
      column: ConditionColumn.ConstructionId,
      operator: SqlOperator.In,
      value: [2, 6, 8, 10, 11, 12],
    });
  }

  if (wheres.length === 0) return {};

  return {
    OR: wheres,
  };
};

type FLOOR_PLAN_CONDITION = {
  roomNum: number;
  planName: "r" | "k" | "dk" | "ldk" | "more";
};
const floorPlanWhere = (
  floorPlans: FLOOR_PLAN_CONDITION[],
): QueryListPageQueryWhereWhereConditions => {
  if (floorPlans.length === 0) return {};

  const planNameMaps: Record<string, string[]> = {
    k: ["K", "SK"],
    dk: ["DK", "SDK", "LK", "SLK"],
    ldk: ["LDK", "SLDK"],
  };

  return {
    OR: floorPlans.map((floorPlan) => {
      if (floorPlan.roomNum > 3)
        return {
          column: ConditionColumn.RoomCount,
          operator: SqlOperator.Gte,
          value: 4,
        };
      if (floorPlan.planName === "r")
        return {
          AND: [
            {
              column: ConditionColumn.RoomCount,
              operator: SqlOperator.Eq,
              value: 1,
            },
            {
              column: ConditionColumn.FloorPlanName,
              operator: SqlOperator.Eq,
              value: "R",
            },
          ],
        };

      return {
        AND: [
          {
            column: ConditionColumn.RoomCount,
            operator: SqlOperator.Eq,
            value: floorPlan.roomNum,
          },
          {
            column: ConditionColumn.FloorPlanName,
            operator: SqlOperator.In,
            value: planNameMaps[floorPlan.planName],
          },
        ],
      };
    }),
  };
};

// [FIXME] コードでの検索に修正する
const areaWhere = (
  regionCondition: RegionConditionType,
): QueryListPageQueryWhereWhereConditions => {
  if (!regionCondition || !regionCondition.hasCheckedShikugun()) return {};
  return {
    OR: regionCondition.getCheckedShikuguns().map((shikugunCondition) => {
      return {
        AND: [
          {
            column: ConditionColumn.PrefectureName,
            operator: SqlOperator.Eq,
            value: `${shikugunCondition.data.todofuken.display_name}`,
          },
          {
            column: ConditionColumn.CityName,
            operator: SqlOperator.Like,
            value: `${shikugunCondition.data.display_name}%`,
          },
        ],
      };
    }),
  };
};

const initialCostScoreWhere = (lower?: string): QueryListPageQueryWhereWhereConditions => {
  if (!lower) {
    return {};
  }
  return {
    column: ConditionColumn.InitialCostScore,
    operator: SqlOperator.Gte,
    value: lower,
  };
};

const rentWhere = (
  lower?: string,
  upper?: string,
  includeAdministrationFee = false,
): QueryListPageQueryWhereWhereConditions => {
  if (!lower && !upper) return {};

  const lowerCondition = {
    column: includeAdministrationFee ? ConditionColumn.RentAdministrationFee : ConditionColumn.Rent,
    operator: SqlOperator.Gte,
    value: lower,
  };
  const upperCondition = {
    column: includeAdministrationFee ? ConditionColumn.RentAdministrationFee : ConditionColumn.Rent,
    operator: SqlOperator.Lte,
    value: upper,
  };

  if (!lower && upper) return upperCondition;
  if (lower && !upper) return lowerCondition;

  return {
    AND: [upperCondition, lowerCondition],
  };
};

// [FIXME] コードでの検索に修正する
const stationWhere = (
  routeCondition: RouteConditionType,
  upperFoot?: string,
): QueryListPageQueryWhereWhereConditions => {
  if (!routeCondition && !upperFoot) return {};
  if (!routeCondition.hasCheckedStation() && upperFoot)
    return {
      OR: [
        {
          column: ConditionColumn.WalkingTime_1,
          operator: SqlOperator.Lte,
          value: upperFoot,
        },
        {
          column: ConditionColumn.WalkingTime_2,
          operator: SqlOperator.Lte,
          value: upperFoot,
        },
        {
          column: ConditionColumn.WalkingTime_3,
          operator: SqlOperator.Lte,
          value: upperFoot,
        },
      ],
    };

  if (routeCondition.getCheckedStations().length === 1) {
    return stationWhereCondition(
      routeCondition.getCheckedStations()[0].data.display_name,
      upperFoot,
    );
  }

  return {
    OR: routeCondition.getCheckedStations().map((railwayStation) => {
      return stationWhereCondition(railwayStation.data.display_name, upperFoot);
    }),
  };
};

const stationWhereCondition = (
  displayName: string,
  upperFoot?: string,
): QueryListPageQueryWhereWhereConditions => {
  return {
    OR: [
      {
        AND: [
          {
            column: ConditionColumn.StationName_1,
            operator: SqlOperator.Eq,
            value: displayName,
          },
          {
            column: ConditionColumn.BusStopName_1,
            operator: SqlOperator.IsNull,
          },
          upperFoot
            ? {
                column: ConditionColumn.WalkingTime_1,
                operator: SqlOperator.Lte,
                value: upperFoot,
              }
            : {},
        ].filter(Boolean),
      },
      {
        AND: [
          {
            column: ConditionColumn.StationName_2,
            operator: SqlOperator.Eq,
            value: displayName,
          },
          {
            column: ConditionColumn.BusStopName_2,
            operator: SqlOperator.IsNull,
          },
          upperFoot
            ? {
                column: ConditionColumn.WalkingTime_2,
                operator: SqlOperator.Lte,
                value: upperFoot,
              }
            : {},
        ],
      },
      {
        AND: [
          {
            column: ConditionColumn.StationName_3,
            operator: SqlOperator.Eq,
            value: displayName,
          },
          {
            column: ConditionColumn.BusStopName_3,
            operator: SqlOperator.IsNull,
          },
          upperFoot
            ? {
                column: ConditionColumn.WalkingTime_3,
                operator: SqlOperator.Lte,
                value: upperFoot,
              }
            : {},
        ].filter(Boolean),
      },
    ],
  };
};

const categoryWhere = (
  entity: CategoryConditionEntity,
  selectedCategories?: valueOf<DictionaryQuery>,
): QueryListPageQueryWhereWhereConditions => {
  if (!selectedCategories) return {};

  const categoryWhere = {
    AND: categoryConditions
      .map((category) => {
        if (!selectedCategories[category.id]) return;
        if (category.entity !== entity) return;

        return category.conditions;
      })
      .filter(Boolean),
  };

  if (categoryWhere.AND.length === 0) return {};

  return categoryWhere as { AND: QueryListPageQueryWhereWhereConditions[] };
};

const propertyAgentNameWhere = (name: string): QueryListPageQueryWhereWhereConditions => {
  if (!name) return {};

  return {
    column: ConditionColumn.EstateAgentName,
    operator: SqlOperator.Eq,
    value: name,
  };
};

const excludeIdsWhere = (ids: string[]): QueryListPageQueryWhereWhereConditions => {
  if (ids.length === 0) return {};

  return {
    column: ConditionColumn.Id,
    operator: SqlOperator.NotIn,
    value: ids,
  };
};
